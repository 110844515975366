/*
-----------------------------------------
NAVIGATION
-----------------------------------------
*/

.nav-container {
  margin-left: auto;
}

/*  Hamburger Menu */

.menu-open {
  cursor: pointer;
  width: 17px;
  height: 12px;
  line-height: 19px;
  text-align: right;
  display: flex;
  align-items: center;

  span {
    width: 17px;
    position: relative;
    display: inline-block;

    &,
    &::before,
    &::after {
      height: 2px;
      border-radius: 2px;
      background: var(--black);
      @include transition();
    }

    &::before,
    &::after {
      position: absolute;
      right: 0;
      content: "";
    }

    &::before {
      width: 10px;
      top: -5px;
    }

    &::after {
      width: 15px;
      bottom: -5px;
    }
  }

  &:hover {
    span {
      &::before,
      &::after {
        width: 17px;
      }
    }
  }
}

/* Navigation Container */

.nav-overlay {
  background: var(--white);
  max-width: $nav-width;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 100;
  transition: transform 0.3s;
  // @include translate($nav-width);
  // opacity: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.menu-hidden {
  transform: translate($nav-width, 0);
  display: none;
}
.menu-displayed {
  transform: translate(0, 0);
}
/* Navigation Contents */

.nav-header {
  display: flex;
  align-items: center;
  margin-bottom: 60px;

  .close-nav {
    padding: 5px;
    cursor: pointer;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  img {
    margin-left: auto;
  }
}

.main-nav,
.sub-nav {
  text-align: right;

  li {
    line-height: 1.5em;
  }

  // a {
  //   display: block;
  //   text-decoration: none;
  //   color: var(--black);

  // &:hover {
  //   color: var(--primary);
  // }
  // }
  // a.disabled,
  // a.disabled:hover,
  // a.disabled:active {
  //   color: var(--gray);
  //   cursor: default;
  // }
}

.main-nav {
  margin-bottom: 20px;

  li {
    @include rem-size(32px);
  }

  a {
    padding: 8px 0;
  }
}

.sub-nav {
  margin-top: auto;

  li {
    @include rem-size(16px);

    a {
      padding: 4px 0;
    }
  }

  svg {
    .st0-rename-this {
      fill: var(--primary);
    }
    height: 24px;
    width: 24px;
    margin-left: 8px;
    vertical-align: middle;
  }

  span {
    display: block;
    @include rem-size(12px);
    color: var(--gray);
    padding-right: 30px;
  }
}

/*  Overlay */

.overlay {
  background: rgba(#000, 0.8);
  position: fixed;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  @include transition(opacity);
}

/*  Show Navigation */

.show-nav {
  overflow-y: hidden;

  .overlay {
    left: 0;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* Navigational Links */

.navigational {
  color: var(--gray-light);
  text-decoration: none;

  svg {
    height: 16px;
    width: 8px;
    .st0-rename-this {
      fill: var(--gray-light);
    }
    margin-right: 8px;
    line-height: 1;
    vertical-align: text-bottom;
  }
}
