/*
-----------------------------------------
HOMEPAGE
-----------------------------------------
*/

/* Homepage Slider */

.homepage-slider.glider {
  // margin-bottom: 24px;
}

/* Status Icons */

.status-icons {
  text-align: center;
  display: flex;
  justify-content: center;

  li {
    margin: 0 15px;
    position: relative;

    &[class^="status-"] {
      &::before {
        content: "";
        position: absolute;
        right: 3px;
        top: 3px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  .status-green {
    &::before {
      background: var(--green);
    }
  }

  .status-yellow {
    &::before {
      background: var(--yellow);
    }
  }

  .status-red {
    &::before {
      background: var(--red);
    }
  }

  .icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid var(--off-white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    position: relative;

    svg {
      height: 24px;
      width: 24px;
    }

    .value {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -70%);
      @include rem-size(10px);
      color: var(--gray);
    }
  }

  p {
    @include rem-size(8px);
    color: var(--gray-dark);
    text-transform: uppercase;
    font-weight: 500;
  }
}

/*  Profiles Summary */

.profiles-summary {
  > ul {
    display: flex;
    margin-bottom: 24px;

    > li {
      width: 100%;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(3),
      &:nth-child(4) {
        display: none;

        @include mq("tablet-small") {
          display: block;
        }
      }

      &:nth-child(3) {
        @include mq("phone-wide") {
          display: block;
        }
      }

      @include mq("phone-small") {
        padding: 0 20px;
      }
    }
  }

  .profile-header {
    margin-bottom: 0;
  }
}

/* Streaming and Speed Settings */

.streaming-speed-settings {
  > div {
    border: 1px solid var(--off-white);
    padding: 14px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled {
      background: var(--off-white);
    }
  }

  p {
    @include rem-size(12px);
    color: var(--gray);
    margin-bottom: 0;
  }

  .section-icon {
    margin-bottom: 8px;

    svg {
      width: 14px;
      height: 14px;
      fill: var(--primary);
    }
  }

  .ping {
    color: var(--gray);

    img {
      margin-left: 8px;
      vertical-align: middle;
    }
  }

  .more {
    padding-top: 16px;
    margin-top: auto;

    p {
      color: var(--gray-dark);
    }
  }

  @include mq("phone-small") {
    display: flex;
    justify-content: space-between;

    > div {
      max-width: 250px;
      margin-bottom: 0;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      &.single {
        max-width: 350px;
      }
    }
  }

  @include mq("phone-wide") {
    p {
      @include rem-size(16px);
    }

    .loc {
      p {
        @include rem-size(20px);
      }
    }

    .speed {
      strong {
        @include rem-size(20px);
      }
    }
  }
}

ul.stream-items {
  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .location {
    display: flex;

    p {
      color: var(--black);
    }

    .loc {
      flex: 1;
      margin-right: 16px;
      font-weight: bold;
    }

    .flag {
      width: 16px;
      margin-left: auto;
    }
  }
}

.speed-results {
  display: flex;
  margin: 16px 0;

  > div {
    width: 50%;
    display: flex;
    align-items: flex-start;

    svg {
      height: 8px;
      width: 6px;
      fill: var(--primary);

      @include mq("phone-wide") {
        height: 12px;
        width: 8px;
        margin-top: 10px;
      }
    }
  }

  p {
    color: var(--gray);
  }

  .speed {
    margin-right: 8px;

    strong {
      color: var(--black);
    }
  }
}

.network-status {
  padding-left: 16px;
  position: relative;
  margin: 16px 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &.green {
    &::before {
      background: var(--green);
    }
  }

  &.yellow {
    &::before {
      background: var(--yellow);
    }
  }

  &.red {
    &::before {
      background: var(--red);
    }
  }

  p {
    color: var(--black);
    @include rem-size(16px);
    font-weight: bold;
  }
}

/* Device List */

.device-list {
  li {
    display: flex;
    padding: 20px 0;
  }

  .icon {
    width: 32px;
    margin-right: 8px;

    .image-container {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      transform: translateY(-4px);
    }
  }

  .data {
    flex: 1;
  }

  .name {
    margin-bottom: 4px;
    font-weight: 500;
  }
}
