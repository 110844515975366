/*
-----------------------------------------
FAQ
-----------------------------------------
*/

.faq {
  list-style: none;
  margin-left: -($site-padding);
  margin-right: -($site-padding);
  padding-left: 0;

  > li {
    padding: 18px $site-padding;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      right: $site-padding;
      height: 1px;
      background: var(--off-white);
    }
  }

  .question-container {
    display: flex;
    cursor: pointer;

    .question {
      flex: 1;

      p {
        @include rem-size(16px);
        font-weight: 500;
      }
    }

    .status {
      flex-shrink: 0;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: var(--gray-light);
    }
  }

  .answer {
    padding-top: 20px;

    p,
    li {
      @include rem-size(12px);
    }
  }
}
