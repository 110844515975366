/*
-----------------------------------------
WIDTHS
-----------------------------------------
*/

$site-width: 960px;
$inner-site-width: 664px;
$site-padding: 32px;
$nav-width: 285px;
$altFooterHeight: 78px;

/*
-----------------------------------------
SPACING
-----------------------------------------
*/

$space: 20px;
$space-xxxs: 0.25 * $space;
$space-xxs: 0.375 * $space;
$space-xs: 0.5 * $space;
$space-sm: 0.75 * $space;
$space-md: 1.25 * $space;
$space-lg: 1.5 * $space;
$space-xl: 2 * $space;
$space-xxl: 2.5 * $space;
$space-xxxl: 3 * $space;

/*
-----------------------------------------
FONTS
-----------------------------------------
*/

// Font Family
$font-sans: "Inter var", sans-serif;

// Font Size
$font-size: 16px;
$font-size-small: $font-size - 2;
$font-size-large: $font-size + 2;

/*
-----------------------------------------
BREAKPOINTS
-----------------------------------------
*/

$breakpoints: (
  "desktop-wide": 1248px,
  "desktop": 1024px,
  "desktop-small": 992px,
  "tablet-wide": 850px,
  "tablet": 768px,
  "tablet-small": 720px,
  "phone-wide": 600px,
  "phone": 480px,
  "phone-small": 400px,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
