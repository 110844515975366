/*
-----------------------------------------
Background Highlights
-----------------------------------------
*/

// Normally we would use CSS custom properties, but Edge has issues reading the value for the background for this selector.
/* Streaming Item*/
.sr-item_container {
  padding: 14px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 4px;
  margin: 8px;
  &.disabled {
    opacity: 0.5;
    cursor: initial;
    position: relative;
  }
}
.upper-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.left-column {
  display: flex;
  flex-direction: row;
}
.service_icon-container {
  position: relative;
  width: 50px;
  height: 50px;
}
.service-icon {
  border-radius: 4px;
}
.current-country {
  position: absolute;
  top: -12px;
  right: -12px;
  svg {
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    margin: 1.25px;
    // border: 2px solid gray;
  }
}

.launch-button {
  padding: 10px 20px !important;
  font-size: 0.9rem !important;
}
.sr-service-name {
  margin-left: 18px;
  font-size: 1.4rem;
}
.sr-disable {
  &:hover {
    text-decoration: underline;
  }

  margin-top: 4px;
  height: 20px; // not move down when spinner is shown
  color: gray;
  border: 0;
  display: block;
  font-size: 1rem;
  text-decoration: none;
  outline: none;
  display: flex;
  align-items: center;
}
.countries-list {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.sr-country {
  position: relative;
  cursor: pointer;
}
.sr-country svg {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.sr-country.selected svg {
  border: 3px solid var(--primary);
}
.sr-country.updating svg {
  opacity: 0.3;
}
/* Streaming Item*/

.gray-icon {
  opacity: 0.5;
  cursor: default;
}
::-moz-selection {
  background: #fda23f;
  color: #fff;
}

::selection {
  background: #fda23f;
  color: #fff;
}

.no-underline {
  text-decoration: none;
}
/*
-----------------------------------------
HEAD TAGS
-----------------------------------------
*/

* {
  @include box-sizing();
}

html,
body {
  min-height: 100vh;
}

html {
  /* Prevents webpage zooming for mobile devices change orientation */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: $font-size;
  font-family: $font-sans;
}

body {
  display: flex;
  flex-direction: column;

  &.no-scroll {
    overflow-y: hidden;
  }
}

/*
-----------------------------------------
GENERICS
-----------------------------------------
*/

img {
  max-width: 100%;
}

.relative {
  position: relative;
}

.left {
  float: left;
}

.right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.ion-slides {
  --bullet-background: gray;
  --bullet-background-active: darkgray;
}

/*
-----------------------------------------
MOBILE ADJUSTMENTS
-----------------------------------------
*/

a:link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2);
}

/*
-----------------------------------------
FORM ADJUSTMENTS
-----------------------------------------
*/

::-webkit-input-placeholder {
  color: var(--gray-light);
}
::-moz-placeholder {
  color: var(--gray-light);
}
:-ms-input-placeholder {
  color: var(--gray-light);
}
input:-moz-placeholder {
  color: var(--gray-light);
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="file"],
textarea {
  -webkit-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.BeaconFabButtonFrame {
  border-radius: 50%;
  overflow: hidden;
}
