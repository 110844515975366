/*
-----------------------------------------
MODAL
-----------------------------------------
*/

/* Modal Visual Control */

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

/* Modal Styles */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  overflow: hidden;
  z-index: 100;
}

.modal-container {
  background-color: #fff;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal-header {
  display: flex;
}

.close-modal-header {
  padding: 5px;
  cursor: pointer;
  margin: 0 0 8px auto;
  width: auto;

  svg {
    width: 14px;
    height: 14px;
    display: block;
  }
}

.close-modal * {
  pointer-events: none;
}

.modal-content {
  text-align: center;
  p {
    font-weight: 500;
  }
}
