/*
-----------------------------------------
COLORS
-----------------------------------------
*/

:root {
  // Brand
  --primary: #fda23f;
  // --primary-off-white: #f4f4fa;

  // Secondary
  --green: #9bf2a5;
  --yellow: #f7de54;
  --red: #f29b9b;

  --red-dark: #af5a5a;

  // Gray
  --off-white: #f1f1f1;
  --gray-light: #d1d1d1;
  --gray: #b1b1b1;
  --gray-dark: #5d5d5d;

  // Basic
  --black: #000;
  --white: #fff;
}
