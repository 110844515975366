.position-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.position-abs-center-horizontally {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}
.position-abs-center-vertically {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}
