/*
-----------------------------------------
IMPERSONATE
-----------------------------------------
*/

.is-impersonating {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid var(--red);
    z-index: 3;
    pointer-events: none;
  }
}

.impersonate-banner {
  background: var(--red);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;

  .content {
    padding: 0 ($site-padding/2);
    display: flex;
    align-items: center;
  }

  .icon {
    svg {
      .st0-rename-this {
        fill: var(--white);
      }
      width: 24px;
      height: 24px;
    }
  }

  .control {
    margin-left: auto;
    position: relative;

    &.is-active {
      .end-session-control {
        display: block;
      }
    }
  }

  .impersonate-control {
    padding: 16px;
    cursor: pointer;

    * {
      pointer-events: none;
    }

    p {
      color: var(--white);
      @include rem-size(12px);
      text-align: right;
      font-weight: 500;

      svg {
        .st0-rename-this {
          fill: var(--white);
        }
        width: 8px;
        height: 4px;
        vertical-align: middle;
      }
    }
  }

  .end-session-control {
    display: none;
    position: absolute;
    top: 100%;
    right: -($site-padding/2);
    background: var(--red);
    padding: ($site-padding/4) ($site-padding/2);
  }
}
