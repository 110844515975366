/*
-----------------------------------------
DATA LIST
-----------------------------------------
*/

// Header

.data-list-header {
  display: flex;

  .item-main {
    flex: 1;
  }

  .item {
    width: 40px;
    text-align: center;

    &.bedtime {
      width: 78px;
    }
  }
}

// Data List

ul.data-list {
  list-style: none;
  // margin-left: -($site-padding);
  // margin-right: -($site-padding);
  padding-left: 0;

  .old-device {
    opacity: 0.4;
  }

  > li {
    position: relative;
    margin-bottom: 0;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: $site-padding;
      height: 1px;
      background: var(--off-white);
    }
    &.off::after {
      background-color: #e5e5e5;
    }
    &.off {
      background: var(--off-white);

      .inner {
        opacity: 0.7;
      }
    }

    &.has-error {
      &::after {
        height: 2px;
        background: var(--red);
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 18px 0;
  }

  .image {
    width: 64px;
    margin-right: 8px;

    .image-container {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .value {
    margin-left: auto;
    flex: 1 0 auto;
    display: flex;
    justify-content: end;
    p {
      // color: var(--gray);
      @include rem-size(12px);
      // text-align: right;

      &.has-flag {
        max-width: 200px;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        > svg {
          margin: 0px 5px;
          flex-shrink: 0;
        }
      }

      &.sub-active,
      &.green {
        color: var(--green);
      }

      &.sub-inactive,
      &.red {
        color: var(--red);
      }
    }

    .toggle input + label {
      margin-bottom: 0;
    }

    .actions {
      // button {
      //   padding: 0 8px;
      // }

      svg {
        width: 18px;
        height: 18px;

        &.has-additive {
          width: 21px;
        }
      }

      .has-left-divider {
        padding-left: 5px;
        border-left: 1px solid var(--gray-light);
      }
      .has-right-divider {
        padding-right: 5px;
        border-right: 1px solid var(--gray-light);
      }
    }

    .continue {
      margin-left: 4px;

      svg {
        width: 5px;
        height: 10px;
        .st0-rename-this {
          fill: var(--gray-light);
        }
      }
    }

    .target {
      margin-left: 4px;

      svg {
        .st0-rename-this {
          fill: var(--primary);
        }
        width: 24px;
        height: 24px;
      }
    }
  }

  .sub-data-list {
  }

  .item-notification {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    transition: background-color 4s;

    svg {
      width: 16px;
      height: 16px;
      .st0-rename-this {
        fill: var(--primary);
      }
      display: inline-block;
      margin-left: 8px;

      &.animated {
        animation: rotation 1s infinite linear;
      }
    }

    p {
      @include rem-size(12px);
      margin-bottom: 0;
    }

    &.success,
    &.warning {
      background: transparent;
    }

    &.warning {
      svg .st0-rename-this {
        fill: var(--red);
      }

      p {
        color: var(--red);
      }
    }
  }

  a {
    text-decoration: none;
  }
}
