.form_control-panel {
  margin-top: 12 px;
  &_heading h2 {
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  &_body {
    ul {
      padding-left: 0;
    }
    li {
      display: flex;
      align-items: center;
      list-style: none;
      margin-bottom: 2px;
      img,
      svg {
        margin-right: 4px;
        width: 15px;
        padding-bottom: 1px;
        padding-top: 1px;
      }
      .tick > path {
        fill: var(--green);
      }
      .cross > path {
        fill: var(--red);
      }
      span {
        font-weight: 300;
        font-size: 15px;
      }
    }
  }
}
