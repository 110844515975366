/*
-----------------------------------------
TABLES
-----------------------------------------
*/

table {
}

thead {
}

tfoot {
}

tbody {
}

tr {
}

th {
}

td {
}

.cell5 {
  width: 5%;
}
.cell10 {
  width: 10%;
}
.cell15 {
  width: 15%;
}
.cell20 {
  width: 20%;
}
.cell25 {
  width: 25%;
}
.cell30 {
  width: 30%;
}
.cell35 {
  width: 35%;
}
.cell40 {
  width: 40%;
}
.cell45 {
  width: 45%;
}
.cell50 {
  width: 50%;
}
.cell55 {
  width: 55%;
}
.cell60 {
  width: 60%;
}
.cell65 {
  width: 65%;
}
.cell70 {
  width: 70%;
}
.cell75 {
  width: 75%;
}
.cell80 {
  width: 80%;
}
.cell85 {
  width: 85%;
}
.cell90 {
  width: 90%;
}
.cell95 {
  width: 95%;
}
