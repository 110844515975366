/*
-----------------------------------------
PROGRESS
-----------------------------------------
*/

/* Progress Bars */
.spin {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.progress-text {
  p {
    @include rem-size(12px);
    color: var(--gray);
  }
}

.progress-bar {
  background: var(--off-white);
  position: relative;
  overflow: hidden;

  &,
  .inner {
    height: 8px;
    border-radius: 8px;
  }

  .inner {
    position: absolute;
    left: 0;
    top: 0;
    background: var(--primary);
  }
}

/* Loading Icon */

.updating-icon {
  svg {
    fill: var(--primary);
    display: block;

    &.animated {
      animation: rotation 1s infinite linear;
    }
  }

  &.large {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 24px;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 64px;
      height: 64px;
      margin: 0 auto 16px;
    }

    p {
      @include rem-size(16px);
      color: var(--black);
      margin-bottom: 32px;
    }
  }

  &.large-general {
    text-align: center;
    margin: 0 auto 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 64px;
      height: 64px;
      margin: 0 auto 32px;
    }

    p {
      @include rem-size(16px);
      color: var(--black);
      margin-bottom: 0;
    }
  }

  &.large-top {
    margin-top: 75px;
  }
}
