@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}
@layer base {
  :root {
    --ion-font-family: "Inter var";
  }
  img {
    @apply border-none;
  }
  /* a {
    color: var(--primary);
    text-decoration: underline;
  } */
  ion-icon {
    pointer-events: none;
  }
  svg {
    @apply inline-block;
  }
  h1,
  h2,
  /* h3, */
  h4,
  h5 {
    @apply mb-4 capitalize;
  }
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  /* h3 {
    @apply text-xl;
  } */
  h4 {
    @apply text-xl;
  }
  h5 {
    @apply text-lg;
  }
  h6 {
    @apply text-sm capitalize;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: black;
    color: black;
    caret-color: black;
  }

  /* p {
    @apply mb-4;
  } */
}
