/*
-----------------------------------------
BUTTONS
-----------------------------------------
*/

.button,
button.button {
  cursor: pointer;
  display: inline-block;
  border: none;
  -webkit-appearance: none;
  background: var(--primary);
  color: var(--white);
  border-radius: 4px;
  padding: 13px 36px;
  text-decoration: none;
  @include rem-size(16px);
  line-height: 1;
  font-family: $font-sans;
  border: 2px solid var(--primary);
  font-weight: bold;
  @include transition("background");

  &:disabled {
    color: var(--gray-light);
    background: var(--white);
    border-color: var(--gray-light);
  }

  &.secondary {
    background: var(--white);
    border-color: var(--primary);
    color: var(--primary);
  }

  &.white {
    background: var(--white);
    border-color: var(--white);
    color: var(--primary);
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    vertical-align: -6px;
  }

  &.mobile-block {
    display: block;

    @include mq("phone") {
      display: inline-block;
    }
  }

  &.secondary-red {
    background: var(--white);
    border-color: var(--white);
    color: var(--red);
    font-weight: bold;
    @include rem-size(12px);
    outline: none;
  }

  &.no-wrap {
    white-space: nowrap;
  }
}

.button-group {
  margin-top: 24px;

  .button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.button-group-center {
    text-align: center;

    .button {
      margin: 0 10px;

      &.mobile-block {
        margin: 0;
      }
    }
  }
}

// button {
//   &.unstyled {
//     border: none;
//     outline: none;
//     background-color: inherit;
//     padding: 0;
//   }
//   &.no-action {
//     cursor: inherit;
//   }
// }
