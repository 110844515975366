/*
-----------------------------------------
PROFILES
-----------------------------------------
*/

.choose-avatar {
  margin: 16px 0;

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  }

  li {
    text-align: center;
  }
}
