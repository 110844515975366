/*
-----------------------------------------
DETAILS
-----------------------------------------
*/

.details-header {
  text-align: center;

  $imageSize: 64px;

  h3 {
    color: var(--black);
    font-weight: 500;
    @include rem-size(16px);
  }

  .image-container {
    width: $imageSize;
    height: $imageSize;
    border-radius: 50%;
    overflow: hidden;
  }

  .status {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--white);
    border-radius: 50%;

    svg {
      width: 24px;
      height: 24px;
      .st0-rename-this {
        fill: var(--primary);
      }
    }
  }

  p {
    @include rem-size(16px);
    margin-bottom: 5px;
    font-weight: 500;
  }

  .subinfo,
  .info {
    @include rem-size(12px);
    color: var(--gray);
  }
}

.actions {
  text-align: center;

  ul {
    margin-top: 8px;
  }
  ul.center {
    display: flex;
    align-items: center;
  }

  li {
    display: inline-block;
    vertical-align: top;
  }

  a {
    padding: 3px 0;
    display: block;
  }

  svg {
    width: 24px;
    height: 24px;

    .st0-rename-this {
      fill: var(--primary);
    }

    &.large {
      width: 30px;
      height: 24px;
    }

    &.gray .st0-rename-this {
      fill: var(--gray-light);
    }
  }
}

// This needs to be revisited as I need to know what the final content will be (image or svg)

.icon-container {
  text-align: center;
  margin-bottom: 32px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
