/*
-----------------------------------------
TYPOGRAPHY
-----------------------------------------
*/

/*  Basics */

// a,
// button {
//   cursor: pointer;
//   color: var(--primary);
//   @include transition();
//   text-decoration: underline;
//   margin: 0;
//   padding: 0;
//   font-size: 100%;
//   vertical-align: baseline;
//   background: transparent;

//   &:hover {
//     text-decoration: none;
//   }

//   &.small {
//     @include rem-size(12px);
//   }

//   &.has-icon {
//     border: none;
//     outline: none;
//     padding: 0;
//     text-decoration: none;
//     color: var(--gray-dark);
//     border-bottom: 1px solid var(--gray-dark);
//   }

//   &.red {
//     color: var(--red);
//     border-bottom-color: var(--red);

//     .arrow-link {
//       .st0-rename-this {
//         fill: var(--red);
//       }
//     }
//   }
// }

.arrow-link {
  height: 7px;
  width: 9px;

  .st0-rename-this {
    fill: var(--gray-dark);
  }
}

// p {
//   @include rem-size($font-size);
//   line-height: 1.4;
//   color: var(--black);
//   margin-bottom: 1.2em;

//   &:last-child {
//     margin-bottom: 0;
//   }
// }

// ul,
// ol {
//   margin-bottom: 1.2em;
//   padding-left: 20px;

//   li {
//     margin-bottom: 1.2em;
//   }
// }

/* Headers */

h1,
// h2,
// h3,
h4,
h5,
h6 {
  color: var(--black);
  line-height: 1.4em;
}

// h2 {
//   @include rem-size(24px);
//   font-weight: bold;
//   margin-bottom: 16px;

//   span {
//     color: var(--gray);
//     font-weight: normal;
//     @include rem-size(12px);
//   }
// }

// h3 {
//   @include rem-size(12px);
//   font-weight: normal;
//   color: var(--gray);
// }

h4 {
  @include rem-size(16px);
  font-weight: normal;
  margin-bottom: 16px;
}

h6 {
  @include rem-size(12px);
  color: var(--gray);
  font-weight: normal;
}

/* General Typography */

.subinfo,
.info {
  @include rem-size(12px);
  color: var(--gray);
}

//TODO to be moved into VPN connection config component, when it's moved to a standalone component
.text-black {
  color: black !important;
}
