/*
-----------------------------------------
MASTHEAD
-----------------------------------------
*/

.site-masthead {
  .content {
    padding: 20px ($site-padding/2) 16px;
    display: flex;
    align-items: center;
    background: var(--white);
    position: relative;
  }
}
