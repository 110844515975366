/*
-----------------------------------------
FOOTER
-----------------------------------------
*/

/* Site Footer */

.site-footer {
  text-align: center;

  .content {
    padding: 18px ($site-padding/2);
    background: var(--black);
  }

  p {
    color: var(--white);
    @include rem-size(12px);
  }
}

/* Save Footer */

.save-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  height: $altFooterHeight;

  .content {
    background: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px ($site-padding/2);
  }
}

/* Support Footer */

.support-footer {
  text-align: center;

  .content {
    padding: 18px ($site-padding/2);
  }

  p {
    @include rem-size(12px);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--primary);
    vertical-align: -6px;
  }

  a {
    color: var(--black);
  }
}
