/*
-----------------------------------------
VENDOR OVERRIDES
-----------------------------------------
*/

.tns-item {
  text-align: center;
}

.tns-nav {
  text-align: center;

  button {
    display: inline-block;
    height: 4px;
    width: 4px;
    padding: 0;
    border: 1px solid var(--gray-light);
    border-radius: 50%;
    margin: 0 4px;

    &.tns-nav-active {
      background: var(--gray-light);
    }
  }
}
