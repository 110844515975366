/*
-----------------------------------------
LAYOUT
-----------------------------------------
*/

ion-content {
  --background: inherit;
}

.content {
  max-width: $site-width;
  margin: 0 auto;
  position: relative;
}

.module {
  margin-bottom: 20px;
}

.module-section {
  padding: 30px $site-padding;
  margin-left: -$site-padding;
  margin-right: -$site-padding;

  &.off {
    background: var(--off-white);

    .inner {
      opacity: 0.5;
    }
  }
}

/* Responsive Video */

.video-container {
  height: 0;
  position: relative;
  margin: 40px 0;
  /* 
    Falls back to 16/9, but otherwise uses ratio from HTML

    If you need something different use: 
      <div class="video-container(style='--aspect-ratio: 9 / 16;')">
  */
  padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
