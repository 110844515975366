/*
-----------------------------------------
CHARTS
-----------------------------------------
*/

.chart-filters {
  margin-bottom: 32px;
}

.chart-range {
  display: flex;
  justify-content: space-between;

  button {
    padding: 8px 16px;
    border-radius: 6px;
    color: var(--gray);
    @include rem-size(12px);
    line-height: 1.5em;
    cursor: pointer;

    &.active {
      background: var(--off-white);
      font-weight: bold;
      color: var(--black);
    }
  }

  @include mq("phone-wide") {
    justify-content: flex-start;

    li {
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
