/*
-----------------------------------------
NOTIFIFICATIONS
-----------------------------------------
*/

.empty-message {
  background: var(--off-white);
  padding: 32px 16px;
  border-radius: 12px;
  margin: 16px 0 32px;

  p {
    @include rem-size(12px);
    color: var(--gray);
  }
}

.header-notification {
  position: absolute;
  z-index: 50;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 16px;
  text-align: center;
  background: var(--gray);
  cursor: pointer;
  border: none;
  outline: none;

  p {
    color: var(--off-white);
    @include rem-size(12px);

    &:last-child {
      margin-bottom: 0;
      margin-right: 25px;
    }
  }

  .close {
    position: absolute;
    right: 15px;
    top: 9px;
    padding: 10px;
    cursor: pointer;

    svg {
      display: block;
      width: 10px;
      height: 10px;
      .st0-rename-this {
        fill: var(--off-white);
      }
    }
  }

  &.error {
    background: var(--red);
  }
  &.warn {
    background: var(--yellow);
  }
  &.info {
    background: var(--primary);
  }
  &.success {
    background: var(--green);
  }
  &.success,
  &.warn {
    p {
      color: var(--gray-dark);
    }
    svg {
      .st0-rename-this {
        fill: var(--gray-dark);
      }
    }
  }
}
