/*
-----------------------------------------
PROGRESS DOTS
-----------------------------------------
*/

.progress-dots {
  background: var(--off-white);
  margin-left: -$site-padding;
  margin-right: -$site-padding;
  margin-bottom: 30px;
  padding: 0 $site-padding;

  .content {
    padding: 16px 0;
  }
}

.progress-dot-bar {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;

  li {
    text-align: center;
    width: 100%;
    position: relative;

    &:before,
    &:after {
      content: "";
      height: 2px;
      background-color: var(--gray);
      position: absolute;
      z-index: 1;
      width: 100%;
      left: -50%;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.25s ease-out;
    }

    &:first-child:before,
    &:first-child:after {
      display: none;
    }

    &:after {
      background-color: var(--primary);
      width: 0%;
    }

    &.is-complete + .is-current:after,
    &.is-complete + .is-complete:after {
      width: 100%;
    }

    &.is-current,
    &.is-complete {
      .icon {
        background-color: var(--primary);
      }
    }
  }

  .icon {
    margin: 0 auto;
    width: 12px;
    height: 12px;
    background-color: var(--gray);
    border-radius: 50%;
    max-width: 100%;
    position: relative;
    z-index: 10;
    transition: all 0.25s ease-out;
  }
}
